<template>
  <div class="conferencing-item" style="position: relative">
    <div class="icon">
      <img src="../../../assets/img/video/video.png" style="width: 70px;height: 36px;" />
    </div>
    <div class="info">
      <div class="title">
        <span style="flex: 1">{{ room.name }}</span>
        <img src="../../../assets/img/video/members.png" style="width: 24px;height: 18px;margin-right: 10px"/>
        <span style="font-size: 16px">{{ room.num }} / {{room.max}}</span>
      </div>
      <div class="time">{{time}}</div>
      <div class="host">
        <span style="flex: 1">主持人：{{ (room.user && room.user.name) || '管理员' }}</span>
        <img src="../../../assets/img/video/column-chart.png" style="width: 21px;height: 14px;margin-right: 10px"/>
        <span style="font-size: 16px">进行中</span>
      </div>
    </div>
  </div>
</template>
<script>
import format from '@/util/timeFormat'
export default {
  props: {
    room: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    time () {
      return this.room.time ? format.formatDate(this.room.time) : ''
    }
  }
}
</script>
<style lang="scss">
.conferencing-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 148px;height: 148px;
  }
  .info{
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-right: 30px;
  }
  .title{
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .time{
    font-size: 16px;
    font-weight: 400;
    color: #DFE7F7;
    margin-top: 22px;
  }
  .host{
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #DFE7F7;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
</style>
