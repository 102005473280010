const Conferencing = function (gateway, options) {
  this.options = options
  this.gateway = gateway
  this.janus = null
  this.opaqueId = (function () {
    return 'opaqueId-' + window.Janus.randomString(12)
  })()
  this.privateId = null
  this.handler = {}
  this._init()
}

Conferencing.prototype._init = function () {
  const _this = this
  window.Janus.init({
    debug: true,
    callback: function () {
      if (!window.Janus.isWebrtcSupported()) {
        console.log('No WebRTC support... ')
        return
      }
      // 创建会话
      _this.janus = new window.Janus({
        server: _this.gateway,

        success: function () {
          _this.options.success()

          // if (_this.options.plugin.indexOf('videoRoom') > -1) {
          //   _this.janus.attach({
          //
          //     plugin: 'janus.plugin.videoroom',
          //
          //     opaqueId: this.opaqueId,
          //
          //     success: function (pluginHandle) {
          //       _this.handler.videoRoom = pluginHandle
          //       _this.options.onVideoRoomSuccess(pluginHandle)
          //     },
          //
          //     webrtcState: function (on) {
          //       console.log(on, 'webrtcState')
          //       _this.options.onVideoRoomWebRtcState(on)
          //     },
          //
          //     ondata: function (data) {
          //       console.log(data, '===========================================')
          //     },
          //
          //     onmessage: function (message, jsep) {
          //       console.log(message)
          //       const event = message.videoroom
          //       if (event === 'joined') {
          //         _this.privateId = event.private_id
          //         message.publishers.forEach(item => {
          //           _this.options.createRemoteFeed(item.id, item.display, item.audio_codec, item.video_codec)
          //         })
          //         _this.options.createLocalFeed()
          //         _this.options.onVideoRoomJoined(message)
          //       }
          //       if (event === 'event') {
          //         if (typeof message.publishers !== 'undefined') {
          //           message.publishers.forEach(item => {
          //             _this.options.createRemoteFeed(item.id, item.display, item.audio_codec, item.video_codec)
          //           })
          //         }
          //         if (typeof message.leaving !== 'undefined') {
          //           _this.options.destroyRemoteFeed(message.leaving)
          //         }
          //       }
          //       if (event === 'attached') {
          //         _this.options.createRemoteFeed(message.id, message.display)
          //         // _this.options.newRemoteFeed(event.id, event.display)
          //       }
          //       console.log(jsep, 'jsep')
          //       if (jsep) {
          //         _this.handler.videoRoom.handleRemoteJsep({ jsep: jsep })
          //       }
          //     },
          //
          //     onlocalstream: function (stream) {
          //       console.log(stream, 'onlocalstream')
          //       _this.options.onLocalStream(stream)
          //     },
          //
          //     onremotestream: function (stream) {
          //       console.log(stream, 'onremotestream')
          //     }
          //   })
          // }
        },

        error: function (error) {
          window.Janus.error(error)
        },

        destroyed: function () {
          // window.location.reload()
        }
      })
    }
  })
}

Conferencing.prototype.attachVideoRoom = function (callback) {
  const _this = this
  let handler = null
  let videoRoomUserId = ''
  _this.janus.attach({

    plugin: 'janus.plugin.videoroom',

    opaqueId: this.opaqueId,

    success: function (pluginHandle) {
      if (!_this.handler.videoRoom) _this.handler.videoRoom = pluginHandle
      handler = pluginHandle
      callback.onSuccess(pluginHandle)
    },

    webrtcState: function (on) {
      callback.onWebRtcState && callback.onWebRtcState(handler, on)
    },

    onmessage: function (message, jsep) {
      console.log(message)
      callback.onMessage && callback.onMessage(message, jsep)
      const event = message.videoroom
      if (event === 'joined') {
        videoRoomUserId = message.id
        _this.privateId = message.private_id
        callback.onJoined(handler, message)
      } else if (event === 'event') {
        if (typeof message.leaving !== 'undefined') {
          callback.onLeaving && callback.onLeaving(handler, message.leaving)
        }
      } else if (event === 'attached') {
        videoRoomUserId = message.id
        callback.onAttached && callback.onAttached(message)
      }
      if (jsep) handler.handleRemoteJsep({ jsep })
    },

    onlocalstream: function (stream) {
      callback.onLocalStream && callback.onLocalStream(stream)
    },

    onremotestream: function (stream) {
      callback.onRemoteStream && callback.onRemoteStream(stream)
    },

    oncleanup: function (res) {
      callback.onClearUp && callback.onClearUp(handler, videoRoomUserId)
    }
  })
}

// 挂载文本插件
Conferencing.prototype.attachTextRoom = function (callback) {
  const _this = this
  let textRoomHandler = null
  this.janus.attach({

    plugin: 'janus.plugin.textroom',

    opaqueId: this.opaqueId,

    success: function (pluginHandle) {
      pluginHandle.send({ message: { request: 'setup' } })
      _this.handler.textRoom = pluginHandle
      textRoomHandler = pluginHandle
      callback.success && callback.success(pluginHandle)
    },

    ondataopen: function (data) {
      callback.onDataOpen && callback.onDataOpen(textRoomHandler, data)
    },

    ondata: function (data) {
      callback.onData && callback.onData(textRoomHandler, data)
    },

    onmessage: function (msg, jsep) {
      console.log(msg, 'msgmsgmsgmsgmsgmsgmsgmsgmsgmsg')
      if (jsep) {
        // Answer
        textRoomHandler.createAnswer({
          jsep: jsep,
          media: { audio: false, video: false, data: true },
          success: function (jsep) {
            textRoomHandler.send({ message: { request: 'ack' }, jsep })
          },
          error: function (error) {
            window.Janus.error('WebRTC error:', error)
          }
        })
      }
    }
  })
}

export default Conferencing
