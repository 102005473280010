export default {
  formatDate(date) {
    var date = new Date(date);
    var YY = date.getFullYear() + '-';
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return YY + MM + DD + " " + hh + mm + ss;
  }
  ,
  timeStamp(second_time) {
    var time = parseInt(second_time) + '秒'
    if (parseInt(second_time) > 60) {
      var second = parseInt(second_time) % 60
      var min = parseInt(second_time / 60)
      time = min + '分' + second + '秒'

      if (min > 60) {
        min = parseInt(second_time / 60) % 60
        var hour = parseInt(parseInt(second_time / 60) / 60)
        time = hour + '小时' + min + '分' + second + '秒'

        if (hour > 24) {
          hour = parseInt(parseInt(second_time / 60) / 60) % 24
          var day = parseInt(parseInt(parseInt(second_time / 60) / 60) / 24)
          time = day + '天' + hour + '小时' + min + '分' + second + '秒'
        }
      }
    }

    return time
  }
  ,
  formatting(value, formatStr) {
    var self = ''
    if (value) {
      if (typeof value == 'string') {
        value = value.replace(/T/g, ' ');
        value = value.replace(/-/g, '/');
        value = value.replace(/\.\d+/, ' ');
        self = new Date(value)
      } else if (typeof value == 'number') {
        self = new Date(value)
      } else {
        self = value
      }
    } else {
      self = new Date()
    }
    var str = formatStr
    var Week = ['日', '一', '二', '三', '四', '五', '六']
    str = str.replace(/yyyy|YYYY/, self.getFullYear())
    str = str.replace(/yy|YY/, (self.getYear() % 100) > 9 ? (self.getYear() % 100).toString() : '0' + (self.getYear() % 100))
    str = str.replace(/MM/, self.getMonth() > 8 ? (self.getMonth() + 1).toString() : '0' + (self.getMonth() + 1))
    str = str.replace(/M/g, self.getMonth())
    str = str.replace(/w|W/g, Week[self.getDay()])
    str = str.replace(/dd|DD/, self.getDate() > 9 ? self.getDate().toString() : '0' + self.getDate())
    str = str.replace(/d|D/g, self.getDate())
    str = str.replace(/hh|HH/, self.getHours() > 9 ? self.getHours().toString() : '0' + self.getHours())
    str = str.replace(/h|H/g, self.getHours())
    str = str.replace(/mm/, self.getMinutes() > 9 ? self.getMinutes().toString() : '0' + self.getMinutes())
    str = str.replace(/m/g, self.getMinutes())
    str = str.replace(/ss|SS/, self.getSeconds() > 9 ? self.getSeconds().toString() : '0' + self.getSeconds())
    str = str.replace(/s|S/g, self.getSeconds())
    return str
  }
}
