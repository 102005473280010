<template>
  <div class="monitoring-container">
    <div style="height: 60px;line-height: 60px;font-size:26px;font-weight:bold;color:#FFFFFF;">
      <span style="padding-left: 20px">{{userInfo.organizationName}}-视频会议</span>
    </div>

    <div class="clearfix" style="padding: 20px  ;overflow: scroll;position:fixed;top: 60px;bottom: 0;">
      <div style="margin-bottom: 32px" class="clearfix">
        <div
          style="width: 166px;height: 38px;line-height: 38px;background:rgba(19,109,187,0);border:1px solid #136DBB;border-radius:4px;text-align: center;color: #00A2FF;float: right;cursor: pointer"
          @click="$refs.form.show()">
          <span>创建视频会议室</span>
        </div>
      </div>

      <div v-for="(room, index) in roomList" :key="index"
           style="width: 528px;height: 196px;float: left;margin-right: 48px;margin-bottom: 20px;"
           class="conferencing active">
        <div style="height: 158px;background: linear-gradient(135deg, #3B94E7 0%, #2A70E4 100%);border-radius: 8px;"
             @click="onRoomClick(room)">
          <conferencing-item :room="room"></conferencing-item>
        </div>
      </div>
    </div>
    <fy-dialog  ref="form" basic custom-class="transparent">
      <el-form class="transparent">
        <el-form-item label="会议名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="直播间模式">
          <el-radio-group v-model="form.mode">
            <el-radio label="many">多人视频模式</el-radio>
            <el-radio label="one">主播模式</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="参与人数">
          <el-input v-model="form.num"></el-input>
        </el-form-item>
      </el-form>
      <el-button @click="$refs.form.close()">取消</el-button>
      <el-button type="primary" @click="createRoom">创建</el-button>
    </fy-dialog>
  </div>
</template>
<script>
import ConferencingItem from './components/ConferencingItem'
import Conferencing from '../../util/conferencing'
import dialog from './components/Dialog'
import common from './mixins/common'

export default {

  name: 'video-conferencing',

  mixins: [common],

  components: {
    ConferencingItem,
    'fy-dialog': dialog
  },

  data () {
    return {
      conferencing: null,
      roomList: [],
      transaction: this.$tools.uuid(),
      form: {
        name: '', num: '', mode: ''
      }
    }
  },

  computed: {
    clientId () {
      return this.$store.state.user.userInfo.id
    }
  },

  created () {
    this.connect()
  },

  methods: {
    onRoomClick (room) {
      console.log(room)
      let page = 'multiplayer'
      if (room.mode && room.mode === 'one') page = 'single'
      window.open(`/#/video-conferencing/${page}?room=` + room.id + '&conferencingName=' + room.name)
      // this.$router.push({
      //   name: 'video-conferencing-multiplayer',
      //   query: {
      //     room: room.id
      //   }
      // })
    },

    connect () {
      const _this = this
      this.conferencing = new Conferencing(this.gateway, {
        success: function () {
          _this.conferencing.attachVideoRoom({
            onSuccess: function (handler) {
              _this.getRoomList()
            }
          })
          _this.conferencing.attachTextRoom()
        }
      })
    },

    getRoomList () {
      const _this = this
      this.conferencing.handler.videoRoom.send({
        message: { request: 'list' },
        success: function (e) {
          _this.roomList = []
          e.list.forEach(item => {
            let info = { name: item.description }
            if (new RegExp(/"([^"]*)":/g).test(item.description)) {
              const data = JSON.parse(item.description)
              info = { ...info, ...data }
            }

            console.log()
            console.log(item)
            _this.roomList.push({
              ...info,
              num: item.num_participants,
              max: item.max_publishers,
              id: item.room,
            })
          })
        }
      })
    },

    createRoom () {
      const _this = this
      const room = new Date().getTime()
      const desc = {
        name: this.form.name,
        mode: this.form.mode,
        time: new Date().getTime(),
        user: {
          name: this.userInfo.organizationName + this.userInfo.name,
          mobile: this.userInfo.phoneNumber
        }
      }

      this.conferencing.handler.videoRoom.send({
        message: {
          request: 'create',
          room,
          ptype: 'publisher',
          description: JSON.stringify(desc),
          publishers: Number(this.form.num),
        },
        success: function () {
          _this.getRoomList()
          _this.$refs.form.close()
        },
        error: function (res) {
          console.log(res)
        }
      })
      this.conferencing.handler.textRoom.data({
        text: JSON.stringify({
          textroom: 'create',
          room,
          transaction: _this.transaction,
          username: _this.transaction,
          display: 'tester'
        }),
        error: function (reason) {
          console.log(reason)
        }
      })
    }
  }
}
</script>
<style scoped>
.conferencing {
  height: 100%;
  overflow: hidden;
  position: relative;
  background-image: url(../../assets/img/video/item.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.monitoring-container {
  height: 100%;
  overflow: hidden;
  position: relative;
  background-image: url(../../assets/img/body.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.active {
  transform: rotateY(10px);
  animation: transform;
}
</style>
