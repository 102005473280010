import { mapState } from 'vuex'
export default {
  data () {
    return {
      // gateway: 'https://192.168.73.128:8089/janus'
      // gateway: 'https://192.168.66.229:8089/janus'
      gateway: 'https://icetest.xueerqin.net:8089/janus'
    }
  },

  created () {
    console.log(this.userInfo)
  },
  computed: {
    ...mapState({
      userInfo (state) {
        // console.log(state)
        return state.user.countyUserInfo
        // let countyUserInfo = { organizationIdStr: '' }
        // const info = localStorage.getItem('countyUserInfo')
        // if (info) countyUserInfo = JSON.parse(info)
        // return countyUserInfo
      }
    }),

    nickName () {
      return this.userInfo.organizationName + ' - ' + this.userInfo.name
    }
  },

  methods: {
    onBeforeCloseWindow() {
      this.conferencing.janus.destroy()
    }
  }
}
